
import { defineComponent, ref } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const loading = ref<boolean>(false)
    return {
      loading
    }
  }
})
